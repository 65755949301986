import React from "react"
import Squeeze from "../layouts/squeeze-landing"
import Hero from "../components/hero"
import Base from "../layouts/base"
import SEO from "../components/SEO"
import Specs from "../components/specs"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import WhereToBuy from "../components/whereToBuy"
import TextLeftImgRight from "../components/textLeftImgRight"
import ImgLeftTextRight from "../components/imgLeftTextRight"
import CustomerTestimonial from "../components/customerTestimonial"

export default () => (
  <div>
    <SEO
      title="Dash CeramicSteel Whiteboard | Magnetic Dry Erase Board"
      description="This is the description"
    />
    <Base>
      <Hero
        name="Dash"
        headline="Collaborate On-the-Go"
        text="Dash features four locking caster wheels so you can easily move the whiteboard around your business or school and lock it down wherever you need it. A totally portable design, Dash makes it easy to take your meetings or lessons anywhere, anytime, on demand."
        image="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/polyvision_hedwig_mobile_education_v02_option01.jpg"
        color="rgb(121, 213, 220)"
      />
      <Squeeze>
        <TextLeftImgRight
          heading="Double Sided & Magnetic"
          p1="Made from tough CeramicSteel, this dry erase board features a double-sided writing surface housed in a mobile metal frame."
          p2="Like all Panls whiteboards, Dash is fully magnetic from edge to edge thanks to the CeramicSteel dry erase surface material. This material is highly magnetic by nature and requires no additional backing materials or magnetic inserts."
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/dash-white-bg.jpg"
          buybtn="d-none"
        />
        <CustomerTestimonial
        title="First class equipment"
        content="I purchased this board right after the pandemic started. I set it up in my home office in about 15 minutes. It was a life saver as I was able to use this daily during my Zoom calls with both employees and clients. Recently I moved it to my main office and I am able to move it around the office as we are currently utilizing different sections for our meetings in order to continue safe social distancing guidelines. It is super versatile as well as easy to clean. The markers glide so nicely on this board as you write it is amazing."        name="Chris Todd on Sep 04, 2020"
      />
        <ImgLeftTextRight
          heading="Stress-Free Setup"
          p1="Dash will ship disassembled with complete setup instructions included.
          There are only two steps to assembly, and the process is easy enough
          for just one person to complete with an electric screwdriver.
          Follow the included instructions and you’ll be ready to start
          collaborating in no time!"
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/dash-set-up.jpg"
        />
        <TextLeftImgRight
          heading="Fast Cleanup"
          p1="With the CeramicSteel dry erase surface on Dash, cleaning is easy! Panls whiteboards are resistant to virtually all permanent marking materials and the cleaners needed to remove them. That means you are safe to use abrasive pads and solvent-based cleaners like acetone or mineral spirits on our whiteboards without damage. You can also use household detergents or glass cleaners on Panls marker boards, but for daily cleaning we recommend just a clean cloth and some warm water or whiteboard cleaner."
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/dash-cleanup.jpg"
          buybtn="d-none"
        />
        <div className="row mb-4 d-flex text-center">
          <div className="six columns py-2 px-2 bg-light-grey border">
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/diamond-icon.png"
              className="mb-1"
            />
            <h2 className="mb-1">Durable</h2>
            <p>
              The CeramicSteel dry erase surface provides unmatched durability
              when compared to melamine, glass, and painted steel portable
              whiteboards.
            </p>
          </div>
          <div className="six columns py-2 px-2 bg-light-grey border">
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/configure-icon.png"
              className="mb-1"
            />
            <h2 className="mb-1">Configured for You</h2>
            <p>
              For large classes or meetings, line up multiple Dash portable dry
              erase boards side by side to maximize writing space. Then, when
              it’s time to breakout into smaller teams, give each group their
              own board.
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/dash-writing-example.jpg"
            className="img-fluid"
          />
        </div>
        <div className="row mb-4 d-flex text-center">
          <div className="six columns py-2 px-2 bg-light-grey border">
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/magnet.png"
              className="mb-1"
            />
            <h2 className="mb-1">Fully Magnetic</h2>
            <p>
              Like all Panls whiteboards, Dash is fully magnetic from edge to
              edge thanks to the CeramicSteel dry erase surface material. This
              material is highly magnetic by nature and requires no additional
              backing materials or magnetic inserts.
            </p>
          </div>
          <div className="six columns py-2 px-2 bg-light-grey border">
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/ribbon-icon.png"
              className="mb-1"
            />
            <h2 className="mb-1">Lifetime Warranty</h2>
            <p>
              When we say Lifetime Warranty, we mean it. Dash comes standard
              with our comprehensive Lifetime Warranty for as long as you own
              your whiteboard – that just might be the best portable whiteboard
              warranty you can get!
            </p>
          </div>
        </div>
        <WhereToBuy />
        <div className="bg-light-grey">
          <h1 className="mt-3 mb-1 px-1 py-1">Tech Specs</h1>
          <hr />
          <Specs
            attribute1="Available Sizes"
            spec1="4x5.5ft"
            attribute2="Surface Type"
            spec2="CeramicSteel"
          />
          <Specs
            attribute1="Weight"
            spec1="64.7lbs"
            attribute2="Warranty"
            spec2="Lifetime"
          />
        </div>
        <div className="row my-3 px-1">
          <h1>CeramicSteel Whiteboard vs Glassboard</h1>
          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-vs-glass.jpg"
            className="img-fluid my-2"
          />
        </div>
        <div className="row px-1 d-flex-center">
          <div className="six columns py-2">
            <h2 className="mb-2 div-heading">The Panls Advantage</h2>
            <p>
              We won’t argue that glassboards look great hanging on the wall, but
              do they look great once you actually try to use them? Can they stand
              up to decades of use and abuse in demanding locations like schools?
              The reality is that glassboards have many disadvantages when
              compared to CeramicSteel whiteboards. Not only is CeramicSteel more
              durable, it’s also easier to clean, simpler to install, and much
              clearer to read even from the back of the room.
            </p>
          </div>
          <div className="six columns py-2">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/5vfoO7UvJSM" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div className="row px-1 d-flex-center">
          <ul>
            <li className="my-2">
              <strong>Sharp Contrast, Excellent Visibility</strong> - With our
              standard Arctic White dry erase surface color on Dash, you get
              excellent marker-to-whiteboard contrast with no shadowing effects.
              Glassboards can’t provide the same level of sharp ink-to-surface
              contrast because of their transparent nature. With glass, you will
              often get a subtle “drop shadow” effect on your writing as the ink
              reflects off the rear pane through the surface. This can make it
              very difficult to read anything written on a glassboard from more
              than a few feet away.
            </li>
            <li className="my-2">
              <strong>See Through the Glare</strong> - Glass isn’t very good at
              dealing with glare because the material is transparent and highly
              reflective. Glass has two reflective panes, one each on the front
              and back, meaning that light can reflect twice as much and make it
              hard to read anything written down. CeramicSteel is available in
              multiple finishes from high gloss to matte and all of these are
              less reflective than glass whiteboards.
            </li>
            <li className="my-2">
              <strong>Magnetic Capability</strong> - The CeramicSteel dry erase
              surface is highly magnetic by nature, right out of the box. Glass
              is not a magnetic material, so to make a glassboard magnetic you
              need to add materials. This usually takes to form of a rigid
              backing material which adds weight and cost to the glassboard.
              With Panls CeramicSteel, there are never any additional components
              needed to make your whiteboard magnetic.
            </li>
            <li className="my-2">
              <strong>Shipping Costs</strong> - Let’s face it – shipping
              anything large with strange dimensions is going to incur some
              higher shipping fees. That said, these fees can be even higher for
              glassboards because they are fragile and must be shipped standing
              straight up to prevent damage. Panls CeramicSteel whiteboards are
              incredibly durable and are highly resistant to impact damage –
              because of this, our whiteboards can be shipped in any orientation
              with no special handling required.
            </li>
            <li className="my-2">
              <strong>Installation</strong> - CeramicSteel dry erase boards are
              much lighter than glassboards and feature simple installation
              thanks to included instructions and mounting templates. A glass
              dry erase board is often much heavier than a CeramicSteel board
              and requires three or more people to be hung safely.
            </li>
            <li className="my-2">
              <strong>Safety</strong> - Glass is a fragile material. We've all
              seen glass break before, and it’s never pretty. Even the tempered
              glass which most glassboards are made from is prone to cracking
              and even shattering from only minor drops. The resulting sharp,
              tiny shards of glass are dangerous and difficult to clean up.
              Panls CeramicSteel whiteboards don’t have any of these issues – in
              fact, if you drop your Panls whiteboard it won’t even get a
              scratch!
            </li>
          </ul>
        </div>

        <div className="row">
          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/hedwig_mobile_corporate_v03.jpg"
            className="img-fluid"
          />
        </div>

        <div className="row px-1">
          <h1 className="my-2">Dash Whiteboard Applications</h1>
          <p>
            A mobile whiteboard with wheels – that’s basically what Dash is, but
            in reality it’s much more. Think of the possibilities with
            versatility like this. With the freedom to take your dry erase board
            anywhere, having the favorite conference room or classroom doesn’t
            matter! With a whiteboard that moves, you have the tools you need to
            collaborate anywhere.
          </p>
        </div>

        <div className="row my-3">
          <div className="four columns">
            <ul>
              <li className="">
                <p className="bold">Interior Design</p>
              </li>
              <li className="">
                <p className="bold">Architecture</p>
              </li>
              <li className="">
                <p className="bold">Hospitality</p>
              </li>
              <li className="">
                <p className="bold">Oil and Gas</p>
              </li>
            </ul>
          </div>
          <div className="four columns">
            <ul>
              <li className="">
                <p className="bold">Technology</p>
              </li>
              <li className="">
                <p className="bold">Construction</p>
              </li>
              <li className="">
                <p className="bold">Healthcare</p>
              </li>
              <li className="">
                <p className="bold">Education</p>
              </li>
            </ul>
          </div>
          <div className="four columns">
            <ul>
              <li className="">
                <p className="bold">Sports</p>
              </li>
              <li className="">
                <p className="bold">Finance</p>
              </li>
              <li className="">
                <p className="bold">Military</p>
              </li>
              <li className="">
                <p className="bold">Government</p>
              </li>
            </ul>
          </div>
        </div>
        <WhereToBuy />
      </Squeeze>
    </Base>
  </div>
)
